<template lang="pug">
.title
  h3.app-title {{label}}
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @apply flex;
  @apply mb-4;
  .app-title {
    @apply text-base;
    @apply font-semibold;
    @apply text-bluegray-600;
    @apply flex;
    @apply items-center;
    @screen md {
      @apply text-2xl;
    }
  }
}
</style>
